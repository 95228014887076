
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.divider {
  width: 100%;
  height: 1px;
  margin: 0;
  background-color: $color-border;
}

.bold {
  height: 4px;
}
