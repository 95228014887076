
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2px;
}

.card {
  position: relative;
  flex: 1 1 100%;
  height: 14rem;
  margin: 0 0 2px;
  background: $color-accent-lineargradient138;
}

.content {
  width: 80%;
  height: 100%;
  padding: $space-md;
  color: $color-accent-fresh;
}

.icon {
  position: absolute;
  bottom: $space-md;
}

@include media-breakpoint-up(sm) {
  .card {
    flex-basis: calc(50% - 1px);
    max-width: calc(50% - 1px);
    margin-right: 2px;

    &:nth-of-type(even) {
      margin-right: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card {
    flex-basis: calc(25% - 2px);
    max-width: 25%;

    &:nth-of-type(2) {
      margin-right: 2px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
