
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.brand {
  background-color: $color-rainforest-dark;
}

.container {
  margin: 0 auto;
  padding-top: $gutters-vertical;
  padding-bottom: $gutters-vertical;
}

.card:not(:last-child) {
  margin-bottom: $space-xxl;
}

.title {
  margin: $space-md 0;
}

.text {
  margin: 0;
}

.title,
.text {
  color: $color-rainforest-light;
}

@include media-breakpoint-up(md) {
  .container {
    display: flex;
    flex-wrap: wrap;
    padding: $space-xxl $space-lg;
  }

  .card {
    width: calc(50% - $space-sm);

    &:nth-child(odd) {
      margin-right: $space-lg;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
}
