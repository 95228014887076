@import 'src/styles/mixins/breakpoints.scss';

$announcement-bar-zIndex: 2;
$regular-box-shadow: rgba(0, 0, 0, 0.1) 0 -3px 4px 0;
$transition-duration: 200ms;
$regular-transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

$grid-breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
) !default;

$navbar-height: 60px;
$navbar-height-desktop: 40px;
$max-width: 1280px;
$max-width-pdp: 1440px;
$max-width-blog: 720px;
$max-width-reviews: $max-width-blog;
$max-width-cart: $max-width-blog;

// * https://www.figma.com/file/pVw1xWx3U1NN5vkAOaJeUU/%F0%9F%8E%A8Styles?node-id=18%3A9718
// *  👉 SPACING 👈 *
// * The basic unit of our Grid geometry is the 8px square unit.
// * Multiples of this units compose the dimensions of columns, rows, boxes, along with their margins and padding.
// * The unit adapts to your content while maintaining a consistent visual rhythm.
$space-xs: 0.5rem; // 8px
$space-sm: 1rem; // 16px
$space-md: 1.5rem; // 24px
$space-lg: 2rem; // 32px
$space-xl: 2.5rem; // 40px
$space-xxl: 3rem; // 48px

//
// Gutters
//
$gutters-horizontal-sm: $space-sm;
$gutters-horizontal-md: $space-lg;
$gutters-vertical: $space-xxl;

//
// Colors
//
$color-rainforest-dark: #1a2a22;
$color-rainforest-primary: #224737;
$color-rainforest-light: #f2f3f2;

$color-laos-primary: #d8d9e1;

$color-kenya-primary: #e5a4b3;
$color-kenya-tint: #fad3dc;
$color-kenya-medium: #fbdce3;
$color-kenya-light: #fce9ed;
$color-kenya-whisper: #fef2f5;

$color-brazil-dark: #e94b24;
$color-brazil-primary: #ee7152;
$color-brazil-medium: #f18d75;
$color-brazil-light: #f6b8a8;
$color-brazil-whisper: #fad4cb;

$color-indonesia-primary: #ae8e35;
$color-indonesia-tint: #f5d589;
$color-indonesia-medium: #f7dda1;
$color-indonesia-light: #faeac4;
$color-indonesia-whisper: #fcf2dc;

$color-ethiopia-primary: #4cb26d;
$color-ethiopia-tint: #76c198;
$color-ethiopia-medium: #91cdad;
$color-ethiopia-light: #bae0cb;
$color-ethiopia-whisper: #d6ece0;

$color-colombia-dark: #4287ca;
$color-colombia-primary: #5f99d2;
$color-colombia-medium: #7faddb;
$color-colombia-light: #afcce8;
$color-colombia-whisper: #cfe0f2;

$color-info-primary: #42b7f3;
$color-info-medium: #97cfec;
$color-info-light: #f2f9fd;

$color-success-primary: #2dcb71;
$color-success-medium: #67e7a0;
$color-success-light: #f8fcfa;

$color-error-primary: #f20200;
$color-error-medium: #e9aaab;
$color-error-light: #f8f3f2;

$color-warning-primary: #ad8e26;
$color-warning-medium: #e0d19d;
$color-warning-light: #fcfaf2;

//
// Primary Colors
//
$color-rainforest-primary: #224737;
$color-rainforest-dark: #1a2a22;
$color-rainforest-light: #f2f3f2;

//
// Accent Colors
//
$color-mantis-primary: #67ad29;
$color-mantis-median: #a0c674;
$color-mantis-opaque: #dadfbe;

$color-explorer-primary: #f4b02c;
$color-explorer-median: #f4c875;
$color-explorer-opaque: #f6dfbf;

$color-bittersweet-primary: #ff8163;
$color-bittersweet-median: #fbab96;
$color-bittersweet-opaque: #f8d6ca;

$color-plush-primary: #ea4e7e;
$color-plush-median: #ee8da7;
$color-plush-opaque: #f4cccf;

$color-fandango-primary: #aa3996;
$color-fandango-median: #c880b5;
$color-fandango-opaque: #e7c7d4;

$color-plum-primary: #5e42ae;
$color-plum-median: #9a86c3;
$color-plum-opaque: #d8c9d9;

$color-indigo-primary: #0f59a6;
$color-indigo-median: #6b93bf;
$color-indigo-opaque: #c8ced7;
$color-indigo-light: #1573d3;

$color-cerulean-primary: #1f9de3;
$color-cerulean-median: #75bce3;
$color-cerulean-opaque: #cbdbe3;

$color-aquamarine-primary: #6ed7de;
$color-aquamarine-median: #a4dfe0;
$color-aquamarine-opaque: #dbe7e2;

//
// Guidance Colors
//
$color-cta-primary: #ff8163;
$color-cta-hovered: #eb4d27;

//
// Action Colors
//
$color-info-primary: #3c78b8;
$color-info-medium: #9fbcdd;
$color-info-text: #1f3b5d;

$color-error-primary: #dd381e;
$color-error-medium: #e9aaab;
$color-error-text: #57180f;

$color-success-primary: #16a04f;
$color-success-medium: #8ccfa9;
$color-success-text: #0b512a;

$color-warning-primary: #fdb550;
$color-warning-medium: #f3e5c8;
$color-warning-text: #693306;

//
// Shades
//
$color-accent-italian: #000000;
$color-accent-french: #1a1a1a;
$color-accent-vienna: #333333;
$color-accent-fullcity: #4d4d4d;
$color-accent-city: #666666;
$color-accent-american: #808080;
$color-accent-newengland: #999999;
$color-accent-cinnamon: #b3b3b3;
$color-accent-drying: #cccccc;
$color-accent-fresh: #ffffff;

//
// Opacities
//
$color-accent-italian90: #000000e6;
$color-accent-italian60: #00000099;
$color-accent-italian30: #0000004d;
$color-accent-fresh30: #ffffff4d;
$color-accent-lineargradient138: linear-gradient(138deg, $color-accent-italian60 0%, #00000000 59%);

//
// Contextual Colors
//
$color-text: $color-accent-italian;
$color-text-negative: $color-accent-fresh;
$color-text-secondary: $color-accent-city;

$color-background-main: $color-accent-fresh;
$color-background-content: $color-accent-fresh;
$color-background-dark: $color-accent-italian90;

$color-rainbow: linear-gradient(
  to left,
  #44ff9a -0.55%,
  #44b0ff 22.86%,
  #8b44ff 48.36%,
  #ff6644 73.33%,
  #ebff70 99.34%
);

$border-inner-new: 0 0 0 2px $color-accent-fresh inset;

//
// Typography
//
$font-primary: 'Albert Sans', Arial, Helvetica, Verdana, sans-serif;
$font-headline: 'Noe Display', Times, Georgia, serif;

$font-x-large: clamp(24px, 2.5vw, 30px);
$font-large: 22px;
$font-medium: 18px;
$font-normal: 18px;
$font-sub: 17px;
$font-x-sub: 15px;
$font-small: 14px;
$font-x-small: 13px;

//
// Elevation & Shadows
//
$box-shadow-lvl-01: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035);
$box-shadow-lvl-02: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.0456112),
  0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0243888);
$box-shadow-lvl-03: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
  0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
  0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
$box-shadow-card-hover: 0 15px 35px rgba(50, 50, 93, 0.07), 0 5px 15px rgba(0, 0, 0, 0.05);

$box-shadow-inset: inset 0px 1px 2px rgba(0, 0, 0, 0.15);

//
// Borders
//
$border-radius: 2px;
$border-radius-large: 4px;
$border-width: 2px;

$color-border: $color-laos-primary;
$border-light: 1px solid $color-border;
$border-strong: $border-width solid $color-border;

//
// PDP Images
//
$pdp-min-image-xy: 330px;
$pdp-max-image-xy: 660px;
