
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
// Subtracting container width from screen width and
// dividing by two to mimic auto margins around container.
$content-column-padding: max(#{$gutters-horizontal-md}, ((100vw - #{$max-width}) / 2) + #{$space-md});

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: $color-rainforest-primary;
}

.contentColumn {
  order: 1;
  color: $color-accent-fresh;
  padding: $gutters-vertical $gutters-horizontal-sm;
}

.imageColumn {
  order: 0;
}

@include media-breakpoint-up(md) {
  .container {
    flex-direction: row;

    &.hasImageOnRightSide {
      .contentColumn {
        order: 0;

        padding-left: $content-column-padding;
        padding-right: $space-lg;
      }

      .imageColumn {
        order: 1;
      }

      .image {
        object-position: right;
      }
    }
  }

  .imageColumn,
  .contentColumn {
    flex: 0 0 50%;
  }

  .contentColumn {
    padding-left: $space-lg;
    padding-right: $content-column-padding;
  }

  .image {
    height: 38rem;
    object-fit: cover;
  }
}
