
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.responsive {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  position: relative;
}

.cover {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.background {
  z-index: -1;
}
