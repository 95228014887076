
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.banner {
  display: flex;
  width: 100%;
  min-height: 24rem;
  position: relative;
  background: linear-gradient(85deg, rgba(0, 0, 0, 0.8) 0, transparent 50%);
}

.text {
  min-width: 17rem;
  max-width: 35rem;
  color: $color-accent-fresh;
}

.container {
  align-self: flex-end;
  width: 100%;
}

.button {
  margin-bottom: $space-xs;
  margin-right: $space-sm;
}

@include media-breakpoint-up(lg) {
  .banner {
    min-height: 38rem;
  }
}
