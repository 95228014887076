
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.root {
  margin: $space-sm 0;

  a {
    text-decoration: underline;
  }

  &[data-color='success'] {
    background: $color-success-medium;
    color: $color-success-text;

    a {
      color: $color-success-text;
    }
  }

  &[data-color='info'] {
    background: $color-info-medium;
    color: $color-info-text;

    a {
      color: $color-info-text;
    }
  }

  &[data-color='warning'] {
    background: $color-warning-medium;
    color: $color-warning-text;

    a {
      color: $color-warning-text;
    }
  }

  &[data-color='error'] {
    background: $color-error-medium;
    color: $color-error-text;

    a {
      color: $color-error-text;
    }
  }

  :global(.mantine-Alert-message) {
    line-height: 1.5;
  }

  &.hasTitle {
    :global(.mantine-Alert-icon) {
      margin-top: -1px;
    }
  }

  &:not(.hasTitle) {
    :global(.mantine-Alert-icon) {
      margin-top: 2px;
    }
  }
}
