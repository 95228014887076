
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.mainContainer {
  display: flex;
  flex-direction: column;
}

.headlineWrapper {
  margin-bottom: $space-xl;
  text-align: center;
}

.cardsContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;
  gap: $space-lg;
}

@include media-breakpoint-up(md) {
  .cardsContainer {
    gap: $space-md;

    &.linksContainer {
      gap: $space-lg;
    }
  }
}

@include media-breakpoint-up(lg) {
  .cardsContainer {
    gap: 0;

    &.linksContainer {
      gap: $space-lg;
    }
  }
}
