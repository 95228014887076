
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.cardWrapper {
  text-align: center;
  color: $color-accent-italian;
  width: 100%;
  transition: all 200ms ease;
  flex-grow: 1;
  flex-shrink: 0;

  &.hasLink {
    &:hover {
      color: $color-brazil-primary;
    }
  }

  &.styledCard {
    background-color: $color-accent-fresh;
    box-shadow: $box-shadow-lvl-01;
    border: 1px solid $color-laos-primary;
    border-radius: $border-radius;
  }
}

.cardContainer {
  padding: $space-xl $space-sm;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cardHeadline {
  padding: $space-sm 0 0;

  &.styledCard {
    padding: $space-sm 0;
  }
}

@include media-breakpoint-up(sm) {
  .cardHeadline {
    padding: $space-sm 0 0;
  }
}

@include media-breakpoint-up(md) {
  .cardWrapper {
    width: calc(33% - $space-lg);
  }

  .cardHeadline {
    &.styledCard {
      padding: $space-sm 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .cardWrapper {
    width: calc(20% - $space-lg);
  }

  .cardHeadline {
    hyphens: auto;
  }

  .cardContainer {
    padding: 0 $space-xs;

    &.styledCard {
      padding: $space-xl $space-sm $space-xs;
    }
  }
}

@include media-breakpoint-up(xl) {
  .cardHeadline {
    hyphens: none;
  }
}
